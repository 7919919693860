import React, { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { Body2, COLORS, Spacer } from 'ethos-design-system'

import { useNavigateToApp } from '@/hooks/useNavigateToApp'

import { cmsModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import northstarStyles from '../styles/northstar.module.scss'
import styles from './HighlightSlider.module.scss'

type Timer = ReturnType<typeof setTimeout>
export interface HighlightSliderBaseProps {
  videoChapters: VideoChapters[]
  title: string
  description: string
  buttonText: string
  buttonUrl: string
  video: string
  experimentKey?: string
}
interface VideoChapters {
  chapterTitle: string
  startTime: number
  chapterLength: number
}

const HighlightSlider: React.FunctionComponent<HighlightSliderProps> = ({
  moduleData,
}) => {
  const buttonUrl = moduleData.buttonUrl
  const { handleNavigateToApp } = useNavigateToApp()

  const handleCtaClicked = (): void => {
    cmsModuleAnalytics.ctaClicked({
      properties: {
        ctaLabel: moduleData.buttonText,
        clickthroughUrl: moduleData.buttonUrl,
        module: 'HighlightSlider',
      },
    })
    handleNavigateToApp({}, false, false, false, moduleData.buttonUrl)
  }

  const videoRef = useRef<HTMLVideoElement>(null)
  const [index, setIndex] = useState(-1)
  const [vidSrc, setVidSrc] = useState<string>('')
  const timeoutRef: { current: Timer | null } = useRef(null)
  const defaultDelay = 5
  const videoChapters = moduleData.videoChapters

  function resetTimeout(): void {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    return
  }

  const getStartTime = (idx: number): number => {
    return videoChapters && videoChapters[idx]
      ? videoChapters[idx].startTime
      : 0
  }

  const getDuration = (idx: number): number => {
    if (videoChapters && videoChapters[idx]) {
      return videoChapters[idx].chapterLength
    } else {
      return defaultDelay
    }
  }

  const getClipCount = (): number => {
    return videoChapters ? videoChapters.length : 0
  }

  useEffect(() => {
    if (index < 0) {
      setIndex(0)
    }
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === getClipCount() - 1 ? 0 : prevIndex + 1
        ),
      getDuration(index) * 1000
    )
    if (videoRef.current) {
      videoRef.current.currentTime = getStartTime(index)
    }
    return (): void => {
      resetTimeout()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const options = {
      threshold: 0.01, // Percentage of video that must be visible to trigger intersection
    }

    const video = videoRef.current
    if (video) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (video) {
              if (vidSrc === '') {
                setVidSrc(moduleData.video)
              } else {
                // @see { https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState }
                if (video.readyState !== 4) {
                  video.load()
                }
                video.play()
              }
            }
            setIndex(0)
          }
        })
      }, options)

      observer.observe(video)

      return (): void => {
        observer.unobserve(video)
      }
    }
  }, [moduleData.video, vidSrc])

  const handleClick = (
    idx: number,
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    e.preventDefault()
    setIndex(idx)
  }

  return (
    <div className={styles.all}>
      <div className={styles.videoWrapper}>
        <video
          className={styles.video}
          muted
          ref={videoRef}
          id={moduleData.title}
          loop
          playsInline
        >
          <source src={vidSrc} type="video/mp4" />
        </video>
      </div>
      <div className={styles.textblock}>
        <div className={styles.title}>
          <Body2.Medium500>{moduleData.title}</Body2.Medium500>
        </div>
        <Spacer.H8 />
        <div
          className={classNames(
            northstarStyles.northstarTitle,
            styles.description
          )}
        >
          {moduleData.description}
        </div>
        <Spacer.H40 />
        <div className={styles.slider}>
          <div className={styles.innerSlideShow}>
            <div
              className={styles.slideshowSlider}
              style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >
              {videoChapters &&
                videoChapters.map((chapter, idx) => (
                  <div className={`${styles.slide}`} key={idx}>
                    <div className={index != idx ? styles.inactiveTitle : ' '}>
                      {idx + 1}/{videoChapters.length}
                      {': '}
                      <span className={styles.chapterTitle}>
                        {chapter.chapterTitle}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            <div className={styles.slideshowTabs}>
              {videoChapters &&
                videoChapters.map((chapter, idx) => (
                  <div
                    key={idx}
                    className={classNames([
                      styles.slideshowTab,
                      styles.slideshowTabHover,
                    ])}
                    role="button"
                    tabIndex={0}
                    onClick={(e): void => {
                      handleClick(idx, e)
                    }}
                    onKeyPress={(e): void => {
                      handleClick(idx, e)
                    }}
                    aria-label={`Video chapter ${idx + 1}`}
                  >
                    <div
                      className={`${styles.progressBar} ${
                        styles[`${index === idx ? 'active' : 'inactive'}`]
                      }`}
                      style={
                        index === idx
                          ? {
                              transition: `${getDuration(idx)}s linear`,
                            }
                          : {}
                      }
                    ></div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.button} onClick={handleCtaClicked}>
          <Body2.Regular400 color={COLORS.WHITE}>
            {moduleData.buttonText}
          </Body2.Regular400>
        </div>
      </div>
    </div>
  )
}
interface HighlightSliderProps {
  moduleData: HighlightSliderBaseProps
}

export default HighlightSlider
